<template>
    <div class="content">
        <div class="login">
            <Card>
                <template #content>
                    <div class="install" v-if="showInstallButton" >
                        <Button @click="installPWA()" icon="pi pi-download"/>
                    </div>
                    <img :src="product.logo" alt="Logo" class="logo">

                    <!-- BU -->
                    <form ref="login" class="form" @submit.prevent="login()">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <InputText :disabled="loading" id="username" placeholder="Usuário"></InputText>
                        </div>

                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-lock"></i>
                            </span>
                            <InputText :disabled="loading" id="password" placeholder="Senha" type="password"></InputText>
                        </div>
                        <SelectButton v-if="product.name != 'CQ'" :disabled="loading" v-model="product" :options="productOptions" optionLabel="name"></SelectButton>
                        <div>
                            <Button :disabled="loading" label="Login" type="submit"></Button>
                        </div>
                        <div v-if="loading">
                            <ProgressBar style="height:10px" mode="indeterminate" />
                        </div>
                    </form>
                </template>
            </Card>
            <div class="version">
                v.{{ getVersion() }}
            </div>
        </div>
    </div>
</template>

<script>
import * as api from "@/services/api";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Card from 'primevue/card'
import SelectButton from 'primevue/selectbutton';
import ProgressBar from 'primevue/progressbar';
import {getUser} from "@/services/api";


export default {
    name: 'Home',
    components: {
        InputText,
        Button,
        Card,
        SelectButton,
        ProgressBar
    },
    data() {
        return {
            deferredInstallPrompt: {},
            showInstallButton: false,
            loading: false,
            product: {
                name: 'SG',
                logo: require('/src/assets/logo-SG.svg'),
                color: '#147052'
            },
            productOptions: [
                {
                    name: 'BU',
                    logo: require('/src/assets/logo-BU.svg'),
                    color: '#1e88e5'
                },
                {
                    name: 'SG',
                    logo: require('/src/assets/logo-SG.svg'),
                    color: '#147052'
                }
            ]
        }
    },
    beforeMount() {
        window.addEventListener('beforeinstallprompt', (e) => {
            // Impede que o mini-infobar apareça em mobile
            e.preventDefault();
            // Guarda evento para que possa ser disparado depois.
            this.deferredInstallPrompt = e;
            // Atualiza UI notifica usuário que pode instalar PWA
            this.showInstallButton = true;
            // Opcionalmente, enviar eventos de analytics que promo de instalação PWA foi mostrado.
            console.log(`'beforeinstallprompt' event was fired.`);
        });
    },
    created() {
        let product = api.getProduct()
        if (location.hostname.includes('sortegol')) {
            this.product = {
                name: 'CQ',
                logo: require('/src/assets/logo-SG.svg'),
                color: '#147052'
            }
        }
        else if (product) {
            let productSelected = this.productOptions.filter(e => e.name === product.name)
            if (productSelected[0]) this.product = productSelected[0]
        }
    },
    mounted() {
        let user = getUser()
        if (user){
            this.$refs.login.username.value = user.username
        }
    },
    methods: {
        getVersion(){
            return process.env.VUE_APP_VERSION
        },
        async installPWA() {
            // Esconde a promoção de instalação fornecida pelo app
            this.showInstallButton = false
            // Mostra prompt de instalação
            this.deferredInstallPrompt.prompt();
            // Espera usuário responder ao prompt
            const { outcome } = await this.deferredInstallPrompt.userChoice;
            // Opcionalmente, enviar evento analytics com resultado da escolha do usuário
            console.log(`User response to the install prompt: ${outcome}`);
            // Usamos o prompt e não podemos usar de novo; jogue fora
            this.deferredInstallPrompt = null;
        },
        login() {
            this.startLoading()

            let data = {
                username: this.$refs.login.username.value,
                password: this.$refs.login.password.value
            }

            api.login(data,this.product)
                .then((msg)=>{
                    this.success(msg,2000)
                    this.$router.push('/dashboard')
                }).catch((msg)=>{
                    this.error(msg)
                }).finally(()=>{
                    this.stopLoading()
                })
        },
        startLoading(){
            this.loading = true
        },
        stopLoading(){
            this.loading = false
        }
    }

}
</script>

<style lang="scss" scoped>

.content {
    background-color: #177883;
    background-image: linear-gradient(#0006,#177883,#177883,#177883,#000a);
    margin: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 50px;
    position: relative;

    .p-card.p-component {
        margin: 10px;
    }


    img {
        width: 150px;
    }

    .form {
        width: 100%;

        .p-inputgroup {
            width: calc(100% - 10px);
            margin: 8px 5px;
        }

        button {
            margin: 15px 5px 5px;
            width: calc(100% - 10px);
        }
    }

    .install{
        position: absolute;
        top: 0;
        right: 15px;
    }

    .version{
        position: absolute;
        color: #aaa;
        left: 20px;
        top: 20px;
        font-size: small;

    }
}

</style>
