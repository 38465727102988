export function formatMoney(value){
    let negative = false
    if (value < 0) {
        negative = true
        value = value * -1
    }

    value = parseFloat(value).toFixed(2).split('.');
    value[0] = value[0].split(/(?=(?:...)*$)/).join('.');

    if (negative){
        return '-' + value.join(',');
    }else{
        return value.join(',');
    }
}

/** Despacha um evento do service worker que será ouvido por App.vue */
export function serviceWorkerEvent(event){
    document.dispatchEvent(
        new CustomEvent('serviceWorkerEvent', {
            detail: {
                event: event
            }
        })
    )
}