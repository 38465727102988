import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import mixins from "@/services/mixins";

createApp(App)
    .use(router)            //Router
    .use(PrimeVue)          //PrimeVue
    .use(ToastService)      //PrimeVue Toast
    .mixin(mixins)
    .mount('#app')
