import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import {isLogged} from "@/services/api";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/relatorio',
    name: 'Relatorio',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "relatorio" */ '../views/Relatorio.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


//"MIDDLEWARE" -> Usuarios nao autenticados somente acessam a rota Home
// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from) => {
    if (!isLogged() && to.name !== 'Home') {
        return { name: 'Home' }
    }
})


export default router
