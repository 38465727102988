<template>
    <nav class="main-nav" v-if="$route.name !== 'Home'">
        <router-link to="#">
            <img alt="Logo" class="logo" :src="product().logo" :class="product().name">
        </router-link>
        <router-link class="item" to="/dashboard">
            <i class="pi pi-home icon"></i>
            <div class="label">
                Início
            </div>
        </router-link>
        <router-link class="item" to="/relatorio">
            <i class="pi pi-book icon"></i>
            <div class="label">
                Relatório
            </div>
        </router-link>
        <div class="item">
            <i class="pi pi-ellipsis-v" @click="openMenu"></i>
            <Menu ref="menu" :model="items" :popup="true" ></Menu>
        </div>
    </nav>
    <Toast position="bottom-center" class="toast-group">
        <template #message="toast">
            <div class="message">
                <div class="icon">
                    <i v-if="toast.message.severity === 'success'" class="pi pi-check-circle"></i>
                    <i v-if="toast.message.severity === 'info'" class="pi pi-info-circle"></i>
                    <i v-if="toast.message.severity === 'warn'" class="pi pi-exclamation-triangle"></i>
                    <i v-if="toast.message.severity === 'error'" class="pi pi-times-circle"></i>
                    <i v-if="toast.message.severity === 'updating'" class="pi pi-arrow-circle-down"></i>
                </div>
                <div>
                    {{toast.message.detail}}
                </div>
            </div>
        </template>
    </Toast>

    <div id="app-view">
        <router-view/>
    </div>
</template>

<script>
import {logout,getProduct} from "@/services/api";
import Toast from 'primevue/toast';
import Menu from 'primevue/menu'

export default {
    components: {
        Toast,
        Menu
    },
    data() {
        return {
            items: [
                {
                    label: 'Updatee',
                    icon: 'pi pi-refresh',
                    command: () => {
                        this.info('Esse botão ainda nao faz nada')
                    },
                    visible: false
                },
                {
                    label: 'Sair',
                    icon: 'pi pi-sign-out',
                    command: this.userLogout
                },
            ]
        }
    },
    created() {
        document.addEventListener('serviceWorkerEvent',evt => {
            switch (evt.detail.event){
                case 'updatefound':
                    this.swNotification('Baixando atualização.')
                    break;
                case 'updated':
                    this.swNotification('Aplicativo atualizado.')
                    break;
            }
        })
    },
    methods: {
        userLogout() {
            logout()
            this.$router.push('/')
        },
        product(){
            return getProduct()
        },
        openMenu(event){
            this.$refs.menu.toggle(event);
        }
    },
}
</script>

<style lang="scss">
@import "~primevue/resources/primevue.min.css";
@import "~primeicons/primeicons.css";
@import "~primevue/resources/themes/lara-light-blue/theme";
@import "./styles/variables.scss";
@import "./styles/primevue-style.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;800&display=swap');

body{
    margin: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color;

    #app-view{
        padding: 10px;
    }
}

.main-nav{
    font-family: 'Poppins',sans-serif;
    background-color: $color;
    color: $color-alt;
    height: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 10px;
    

    img.logo{
        width: 35px;
        margin: 0 15px;
        &.BU{
            filter: brightness(20) grayscale(1);
        }
    }

    .item{
        text-decoration: none;
        color: $color-alt;
        margin: 0 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        min-width: 70px;
        position: relative;
        z-index: 1;

        .icon{
            font-size: 20px;
        }
        
        //Fundo do item ativo
        &:before{
            position: absolute;
            transition: all 300ms ease-in-out;
            content: '';
            height: 100%;
            width: 100%;
            background-color: lighten($color,10%);
            z-index: -1;
            inset: 0;
            border-radius: 100vw;
            transform: scale(0);
        }
        

        // NavBar item ativo
        &.router-link-active.router-link-exact-active{
            color: $color-alt;
            
            //Fundo do item ativo
            &:before{
                transform: scale(1);
                border-radius: 5px;
            }
        }

        &:last-child{
            margin-left: auto;
        }
    }

}

.toast-group{
    width: 90% !important;
    opacity: 1;

    // Toast de Mensagem
    &.p-toast .p-toast-message{
        background-color: #eeeeee;
        margin-bottom: 10px;
        min-height: 50px;
        filter: drop-shadow(0px 0px 1px black);
        border-width: 1px 1px 1px 8px;
    }

    // Container da Mensagem
    .p-toast-message-content{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding: 5px;
    }

    // Icone de fechar o toast
    .p-toast-icon-close{
        position: absolute;
        right: 10px;
        top: 10px;
    }

    // Texto do toast
    .message{
        padding-right: 30px;
        display: flex;

        .icon{
            padding-right: 10px;
        }
    }
}

.p-datatable {
    box-shadow: 0 3px 5px #00000066;
    font-family: Poppins,sans-serif;
    font-weight: 400;
    border-radius: 10px;
    overflow: hidden;
    font-size: 15px;

    .p-datatable-thead th[role='cell']{
        background-color: $color;
        color: white;
        
        .pi{
            color:white;
        }
    }
    &.p-datatable-striped .p-datatable-tbody > tr:nth-child(even){
        background-color: rgba($color,0.2)
    }
}

.p-button{
    border-color: $color;
}
</style>
