/* eslint-disable no-console */

import {register} from 'register-service-worker'
import {serviceWorkerEvent} from "@/services/helper";


if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js?${process.env.VUE_APP_VERSION}`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.')
            window.localStorage.serviceWorkerStatus = 'updatefound'

            serviceWorkerEvent('updatefound')
        },
        updated(registration) {
            console.log('New content is available; please refresh.')
            if (window.localStorage.serviceWorkerStatus === 'updatefound') {
                serviceWorkerEvent('updated')
                registration.waiting.postMessage({type: 'SKIP_WAITING'})
                window.location.reload()
            }

            window.localStorage.serviceWorkerStatus = 'updated'
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
